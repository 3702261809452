// Generated by Framer (716dd6f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {uKCCh1bka: {hover: true}};

const cycleOrder = ["uKCCh1bka"];

const variantClassNames = {uKCCh1bka: "framer-v-bs9x0p"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};


function toResponsiveImage_194x2gw(value) {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "uKCCh1bka", image: Ly0gBqiQw = {src: new URL("assets/CagQMiNubrJjEItk0dG96ua0Ws.jpg", import.meta.url).href, srcSet: `${new URL("assets/512/CagQMiNubrJjEItk0dG96ua0Ws.jpg", import.meta.url).href} 397w, ${new URL("assets/1024/CagQMiNubrJjEItk0dG96ua0Ws.jpg", import.meta.url).href} 794w, ${new URL("assets/CagQMiNubrJjEItk0dG96ua0Ws.jpg", import.meta.url).href} 1000w`}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "uKCCh1bka", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-AwS4x", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? "auto"}}>
<motion.div {...restProps} className={cx("framer-bs9x0p", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"uKCCh1bka"} ref={ref} style={{boxShadow: "0px 0px 30px 0px rgba(0,0,0,0.25)", ...style}} transition={transition} {...addPropertyOverrides({"uKCCh1bka-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fit", intrinsicHeight: 1060, intrinsicWidth: 860, pixelHeight: 1060, pixelWidth: 860, ...toResponsiveImage_194x2gw(Ly0gBqiQw)}} className={"framer-4lzc05"} data-framer-name={"Sparetime register"} layoutDependency={layoutDependency} layoutId={"tpZfwZqjM"} style={{filter: "grayscale(1) drop-shadow(0px 0px 13px rgba(0,0,0,0.25))", WebkitFilter: "grayscale(1) drop-shadow(0px 0px 13px rgba(0,0,0,0.25))"}} transition={transition} variants={{"uKCCh1bka-hover": {filter: "grayscale(0) drop-shadow(0px 0px 13px rgba(0,0,0,0.25))", WebkitFilter: "grayscale(0) drop-shadow(0px 0px 13px rgba(0,0,0,0.25))"}}}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-AwS4x [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-AwS4x * { box-sizing: border-box; }", ".framer-AwS4x .framer-1xd8f6v { display: block; }", ".framer-AwS4x .framer-bs9x0p { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: hidden; padding: 10px 10px 10px 10px; position: relative; width: 1080px; }", ".framer-AwS4x .framer-4lzc05 { align-content: center; align-items: center; aspect-ratio: 0.7780979827089337 / 1; display: flex; flex: 1 0 0px; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: var(--framer-aspect-ratio-supported, 1362px); justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 1px; }", ".framer-AwS4x .framer-v-bs9x0p .framer-bs9x0p { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-AwS4x .framer-bs9x0p, .framer-AwS4x .framer-4lzc05 { gap: 0px; } .framer-AwS4x .framer-bs9x0p > *, .framer-AwS4x .framer-4lzc05 > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-AwS4x .framer-bs9x0p > :first-child, .framer-AwS4x .framer-4lzc05 > :first-child { margin-left: 0px; } .framer-AwS4x .framer-bs9x0p > :last-child, .framer-AwS4x .framer-4lzc05 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1382
 * @framerIntrinsicWidth 1080
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"tbbaf1WJH":{"layout":["fixed","auto"]}}}
 * @framerVariables {"Ly0gBqiQw":"image"}
 */
const FramerJrgZngwt5: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerJrgZngwt5;

FramerJrgZngwt5.displayName = "esy";

FramerJrgZngwt5.defaultProps = {height: 1382, width: 1080};

addPropertyControls(FramerJrgZngwt5, {Ly0gBqiQw: {__defaultAssetReference: "data:framer/asset-reference,CagQMiNubrJjEItk0dG96ua0Ws.jpg?originalFilename=image.jpg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerJrgZngwt5, [])